<template>
  <div class="rounded-md bg-indigo-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <XCircleIcon class="h-5 w-5 text-indigo-400" v-on:click="$emit('close')" hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm text-left font-medium text-indigo-800">
          {{ info.message }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { XCircleIcon } from "@heroicons/vue/solid";

export default {
  props: ["info"],
  components: {
    XCircleIcon,
  },
};
</script>
