<template>
  <div
    class="
      border-2 border-current
      rounded-full
      flex
      text-center text-lg
      justify-center
      items-center
    "
    :class="[sizeClass, borderClass]"
  >
    <span class="text-center">{{ initials }}</span>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
    },
    size: {
      type: Number,
      default: 10,
    },
    borderColor: {
      type: String,
      default: "gray-400",
    },
  },
  computed: {
    initials() {
      if (!this.user || !this.user.firstName || !this.user.lastName) {
        return "";
      }
      if (!this.user.firstName) {
        return this.user.lastName.charAt(0);
      }
      if (!this.user.lastName) {
        return this.user.firstName.charAt(0);
      }
      return (
        this.user.firstName.charAt(0).toUpperCase() +
        this.user.lastName.charAt(0).toUpperCase()
      );
    },
    sizeClass() {
      return `w-${this.size} h-${this.size}`;
    },
    borderClass() {
      return `border-${this.borderColor}`;
    },
  },
};
</script>
