import { getSubscriptionData } from "./firm.js";
import { getMemberCount, getInviteCount } from "./teams.js";

// ==================== UTILITIES FOR CHECKING SUBSCRIPTIONS ====================
//
function canUseApp(subscriptionData) {
  return ["trialing", "active"].includes(subscriptionData.plan.status);
}

function canDirectDownload(subscriptionData) {
  if (!canUseApp(subscriptionData)) {
    return false;
  }
  if (
    subscriptionData.plan &&
    ["pro", "executive"].includes(subscriptionData.plan.plan)
  ) {
    return true;
  } else {
    return false;
  }
}

function canLoadPrivateData(subscriptionData) {
  if (!canUseApp(subscriptionData)) {
    return false;
  }

  if (subscriptionData.plan && "executive" == subscriptionData.plan.plan) {
    return true;
  } else {
    return false;
  }
}

async function canAddUser(subscriptionData, firmData) {
  if (!canUseApp(subscriptionData)) {
    return false;
  }
  const member_count = await getMemberCount(firmData.id);
  const invite_count = await getInviteCount(firmData.id);

  const extra_user_add_on = subscriptionData.add_ons.find(
    (add_on) =>
      ["trialing", "active"].includes(add_on.status) && "user" == add_on.add_on
  );
  if (extra_user_add_on) {
    return extra_user_add_on.quantity + 1 < member_count + invite_count;
  } else {
    return false;
  }
}

function canAddApplication(subscriptionData) {
  if (!canUseApp(subscriptionData)) {
    return false;
  }
  //TODO
  return true;
}

async function canCustomizeRules(firm_id) {
  const subscriptionData = await getSubscriptionData(firm_id);
  if (!canUseApp(subscriptionData)) {
    return false;
  }

  if (subscriptionData.plan && (("pro" == subscriptionData.plan.plan) || ("executive" == subscriptionData.plan.plan))) {
    return true;
  } else {
    return false;
  }
}

function canUseTrademark(firm_id) {
  // TODO, need to add an add-on product in paddle and check whether subscription has the add-on
  // temporarily make this true for firm_id "aBe03ywoasYQFz3PjO9u" for test/dev purposes
  if (firm_id === "aBe03ywoasYQFz3PjO9u") {
    return true;
  }
  else {
    return false;
  }
}

export { canUseApp, canDirectDownload, canLoadPrivateData, canAddUser, canAddApplication, canCustomizeRules, canUseTrademark };

