import { createClient } from "./requests.js";
let token = "";
// ==================== READ ====================

function getUSPatentApplication(id) {
  const request = createClient(token);
  return request.get(`/application?id=${id}`);
}

function getIssuedPatentMaintenanceData(applId, patentNum) {
  const request = createClient(token);
  return request.get(
    `/application/maintenance?applicationNumber=${applId}&patentNumber=${patentNum}`
  );
}

function getUSPatAppbyCustNum(custnum, start = 0) {
  const request = createClient(token);
  return request.get(
    `/application/customer-number?id=${custnum}&start=${start}`
  ).catch((error) => {
    console.log("error in get pat app by custom " + error);
    return null;
  });
}

function getUSPatentApplicationCmsData(id) {
  const request = createClient(token);
  return request.get(`/application/cmsData?id=${id}`).catch((error) => {
    console.log("error in get cms " + error);
    return null;
  });
}

function getUSPatentApplicationIfwData(id) {
  const request = createClient(token);
  return request.get(`/application/ifw?id=${id}`).catch((error) => {
    console.log("error in get ifw data " + error);
    return null;
  });
}

function downloadDocument(firm_id, applId, docId) {
  const request = createClient(token);
  return request.get(`/download/pdf?firm_id=${firm_id}&id=${applId}&docId=${docId}`);
}

function enqueuePrivatePair(firm_id, customer_number) {
  const request = createClient(token);
  return request.post("/ppair", {
    customer_number: customer_number,
    firm_id: firm_id,
  });
}

function enqueuePrivatePairPatent(firm_id, patent_number) {
  const request = createClient(token);
  return request.post("/ppair", {
    patent_number: patent_number,
    firm_id: firm_id,
  });
}

function sendInvites(firm_id) {
  const request = createClient(token);
  return request.post("/invites/send_invites", {
    firm_id: firm_id,
  });
}

function acceptInvite(invite_id, firm_id) {
  const request = createClient(token);
  return request.post("/invites/accept_invite", {
    invitation_id: invite_id,
    firm_id: firm_id,
  });
}

function setAuthToken(id) {
  token = id;
}

export {
  setAuthToken,
  getUSPatentApplication,
  getIssuedPatentMaintenanceData,
  downloadDocument,
  getUSPatAppbyCustNum,
  getUSPatentApplicationCmsData,
  enqueuePrivatePair,
  enqueuePrivatePairPatent,
  getUSPatentApplicationIfwData,
  sendInvites,
  acceptInvite,
};
